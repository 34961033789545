import { BrowserRouter as Router,Routes, Route, Link} from 'react-router-dom'  
import ImageGallery from "react-image-gallery";
import properties from './properties/properties.json'
import Footer from './footer'
import PhotoAlbum from "react-photo-album";



function PropertyPage(props){
let number = window.location.pathname.slice(-1)-1

debugger;


const images = [];
  const photos = [];
  for (let i = 0; i < properties[number].photos.length; i++) {
    photos.push({ src: require(`${properties[number].photos[i]}`), width: 100, height: 100 })
    images.push({original: require(`${properties[number].photos[i]}`)})
  }

  if(number === 2 || number === 4 || number === 1){
    return(
      <div>
      <div className='propertyPage'>
      <br/>
      <br/>
      <br/>
      <br/>
      <hr />
      <br/>
      <div class="fein">
      <ImageGallery items={images}/>
      <div class="text">
      <h1>{properties[number].title}</h1>
      <p>{properties[number].desc}</p>
      </div>
      </div>
      <hr/>
      <h2>Photo Album</h2>
      <div className='album'>
      <PhotoAlbum layout="rows" photos={photos}/>
      </div>
      </div>
      <br /><br /><br /><br /><br /><br /><br /><br /><br />
      <Footer />
      </div>
  )
} else {
  return(
    <div>
    <div className='propertyPage'>
    <br/>
    <br/>
    <br/>
    <br/>
    <hr />
    <br/>
    <div class="fein">
    <ImageGallery items={images}/>
    <div class="text">
    <h1>{properties[number].title}</h1>
    <p>{properties[number].desc}</p>
    </div>
    </div>
    </div>
    <br /><br /><br /><br /><br /><br /><br /><br /><br />
    <Footer />
    </div>
)
}
}
        

export default PropertyPage;