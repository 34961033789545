import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom'  


function Navbar(){
    return(
        <div className="navbar">
            <ul>
                    <div className="rest">
                        <Link to="/"><li><a>Home</a></li></Link>
                    </div>
            </ul>
        </div>
    )
};

export default Navbar;