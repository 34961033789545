function Footer(){
    return(
        <div className="footer">
            <br/>
            <div className="footerText">
            <h2>Contact Us!</h2>
            <h3>Phone: 508 588 8994</h3>
            <h3>E-Mail: rtaylor@saphireproperties.com</h3>
            <h3>E-Mail: rws@saphireproperties.com</h3>
            <p>Copyright © 2019 Saphire Properties. All Rights Reserved.</p>
            </div>
            <br/>
        </div>
    )
}

export default Footer