import './App.css';
import ReactDOM from 'react-dom';  
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom'  
import FullHomepage from './fullHomepage';
import Navbar from './Navbar';
import Contact from './contact';
import PropertyPage from './propertyPage';

function App() {
  return(
    <Router>
      <Navbar/>
      <Routes>
        <Route exact path='/' element={< FullHomepage />}></Route>
        <Route exact path='/property/:id' element={< PropertyPage />}></Route>
        <Route exact path="/contact" element={< Contact />}></Route>
      </Routes>
    </Router>
  )
}

export default App;
