import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom'  


function PropertyScroll(props){
        return(
            <Link to={{pathname: `property/${props.id}`}}>
            <div class="card">
            <img src={require(`${props.img}`)} alt="Avatar" style={{width:"100%"}}/>
            <div class="container">
                <h4><b>{props.title}</b></h4>
                <h5>{props.addy}</h5>
            </div>
            </div>
            </Link>
        )
}

export default PropertyScroll;