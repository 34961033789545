import beachbg from './images/Beach.png';
import divider from './images/divider.png';
import Intro from './intro';
import Footer from './footer';
import PropertyScroll from './PropertyScroll';
import properties from './properties/properties.json'

function FullHomepage(){
      return (
        <div>
        <img src={beachbg} style={{width:"99vw"}}></img>
          <Intro />
        <img style={{marginTop:'7vw'}} className='divider' src={divider} draggable="false" alt=""/>

        <div class="parent">
        {properties.map(x=>{
          return(
            <PropertyScroll id={x.id} title={x.title} desc={x.desc} img={x.img} imgSide={x.imgSide} addy ={x.addy} photos = {x.photos}/>
          )
        })}
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer/>
      </div>
      );
}

export default FullHomepage