

function Intro(){
    return(
        <div style={{marginTop:'-35vw'}}>
            <h3 className="bigIntro" >Welcome to Saphire Properties</h3>
            <h6 className="subIntro"> CNN's Money Magazine has voted Sharon, MA as the best place to live! Come and visit the best town in the country. See our properties below.</h6>
            <div className="hide">
            <div className="hidden">
            </div>
            <p className="subIntro" onClick={() => window.scrollTo({ top: 650, behavior: "smooth" })} >Scroll Down</p>
            <p style={{textAlign:"center", marginTop:"-2vw"}}>&darr;</p>
            </div>
        </div>
    )
}

export default Intro